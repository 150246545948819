import './App.css';
import { useEffect, useRef, createRef } from 'react';
import { gsap } from 'gsap'
import { CSSPlugin, TweenLite } from 'gsap/all';

function App() {
  const refCover = useRef();

  const paragraphCount = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,];
  const refParagraphs = useRef(paragraphCount.map(() => createRef()));
  const refDivider = useRef();

  useEffect(() => {
    gsap.registerPlugin(CSSPlugin);
    window.scrollTo(0, 0);

    TweenLite.to(refCover.current, 3, { opacity: 0, delay: 0.3 });
    TweenLite.to(refDivider.current, 1, { opacity: 1, delay: 1.4 });

    // for (let i = 0; i < paragraphCount.length; i++) {
    for (let i = 0; i < 21; i++) {
      // console.log('Paragraph', refParagraphs, refParagraphs.current[i]);
      if (refParagraphs.current[i]) {
        console.log('Hide paragraph', i, refParagraphs.current[i]);
        TweenLite.to(refParagraphs.current[i], 3, { opacity: 0.5, delay: 0.4 * i + 1 });
      } else {
        break;
      }
    }

    setTimeout(() => {
      TweenLite.set(refCover.current, { display: 'none' });
    }, 5000);
  }, []);

  return (
    <>
      <main>
        <h1>What do you dream about?</h1>
        <p ref={(el) => (refParagraphs.current[0] = el)}>
          Chasing my dreams was like a cat chasing a laser pointer. I never reached them. And when I did, I
          didn't want it.
        </p>
        <p ref={(el) => (refParagraphs.current[1] = el)}>
          Recognizing the privilege and luck I've had, I look back and write this:
        </p>
        <div ref={refDivider} className="divider" />
        <p ref={(el) => (refParagraphs.current[2] = el)}>
          When I was 8, I dreamed of becoming a professional soccer player. I just liked playing soccer and
          celebrated my heroes like{' '}
          <a href="https://en.wikipedia.org/wiki/Park_Ji-sung" target="_blank" rel="noreferrer">
            Park Ji-sung of Manchester United.
          </a>
        </p>
        <p ref={(el) => (refParagraphs.current[3] = el)}>
          When I was 12, I wanted to become a renowned app developer. I saw figures like{' '}
          <a href="https://www.youtube.com/watch?v=DBXZWB_dNsw" target="_blank" rel="noreferrer">
            Santiago Gonzalez
          </a>{' '}
          and{' '}
          <a
            href="https://www.ted.com/talks/thomas_suarez_a_12_year_old_app_developer/transcript?language=en"
            target="_blank"
            rel="noreferrer"
          >
            Thomas Suarez
          </a>
          ; I imagined I could one day be like them. I found my corner in{' '}
          <a
            href="https://www.reddit.com/r/IndieGaming/comments/2ei7v9/twerkopter_when_miley_cyrus_meets_swing_copters/"
            target="_blank"
            rel="noreferrer"
          >
            building Windows Phone games
          </a>
          .
        </p>
        <p ref={(el) => (refParagraphs.current[4] = el)}>
          When I was 15, I wished to one day work at a top tech company. Was it that I wanted the money? Or
          did I drool over all the massage chairs and free lunch? It certainly would have made my parents very
          happy.
        </p>
        <p ref={(el) => (refParagraphs.current[5] = el)}>
          When I was 16, I wanted to work for Ueno so badly. It was when I started to take design seriously.{' '}
          <a href="http://www.jennyjohannesson.com/" target="_blank" rel="noreferrer">
            Jenny Johannesson
          </a>
          ,{' '}
          <a href="https://geneross.co/" target="_blank" rel="noreferrer">
            Gene Ross
          </a>
          ,{' '}
          <a href="http://benmingo.com/" target="_blank" rel="noreferrer">
            Ben Mingo
          </a>
          , and{' '}
          <a href="https://robin-noguier.com/" target="_blank" rel="noreferrer">
            Robin Noguier
          </a>{' '}
          were doing work on another dimension. I wanted to learn from them.
        </p>
        <p ref={(el) => (refParagraphs.current[6] = el)}>
          When I was 17, I stopped designing. And I stopped dreaming. It was a hard time.
        </p>
        <p ref={(el) => (refParagraphs.current[7] = el)}>
          When I was 19, I wanted to become a Georgia Tech student. I lived in constant anxiety and paranoia
          with schoolwork—to transfer to a school where I felt people valued design. There were Asian American
          pressures too, but looking back, I think I just wanted friends who cared deeply about the things I
          cared about. This dream came true; I became a{' '}
          <a
            href="https://en.wikipedia.org/wiki/Georgia_Tech_Yellow_Jackets"
            target="_blank"
            rel="noreferrer"
          >
            Yellow Jacket
          </a>
          , yet I felt a lot of emptiness after.
        </p>
        <p ref={(el) => (refParagraphs.current[8] = el)}>
          That was also when I desperately wanted to get back into product design. I poured my heart into a
          portfolio and emailed everyone. I could have only dreamed of working with my heroes like{' '}
          <a href="https://www.juliezhuo.com/" target="_blank" rel="noreferrer">
            Julie Zhuo
          </a>
          . This dream came true; I'm living the dream right now.
        </p>
        <p ref={(el) => (refParagraphs.current[9] = el)}>
          Since then, I wanted to become undeniably good at design—to one day be at the frontlines, solving
          the most critical challenges of this world. I looked at figures like Jesus, who with all his holy
          power, spent time with the poor, sick, and the broken: the people who most needed his help. I wanted
          to be the{' '}
          <a href="https://www.youtube.com/watch?v=AVlww2uJHAc" target="_blank" rel="noreferrer">
            friendly neighborhood Spider-man
          </a>{' '}
          for the people I love, for this world.
        </p>
        <p ref={(el) => (refParagraphs.current[10] = el)}>
          I share this because my dreams have always evolved. In each evolution, I went deeper in figuring out
          why I cared so much about something.
        </p>
        <p ref={(el) => (refParagraphs.current[11] = el)}>
          And almost every single time, what I thought I wanted was not what I actually desired. Unwanted
          ideas were often put upon me by the people around me.
        </p>
        <p ref={(el) => (refParagraphs.current[12] = el)}>Now I'm 20.</p>
        <p ref={(el) => (refParagraphs.current[13] = el)}>
          I've learned to look at things differently, and it comes down to this.
        </p>
        <p ref={(el) => (refParagraphs.current[14] = el)}>
          There's an image in my head, one that warms my heart and sparks my eyes. It's the image of future me
          living in the future world.
        </p>
        <p ref={(el) => (refParagraphs.current[15] = el)}>
          He puts himself in line for the people he loves, unbounded time-wise and financially to explore his
          curiosities, and is equipped to fight painful hardships. He leads his adventures with a warm heart
          while always making leaps of faith. And he lives in a loving world where people are empowered to
          live a life of freedom and joy, driven by meaning and purpose.
        </p>
        <p ref={(el) => (refParagraphs.current[16] = el)}>
          For this world to become more the one we live in, the most painful and hurting human challenges need
          to be tackled: poverty, loneliness, discrimination... My relentless, unending fight against these
          problems happens through building digital products. Design and code are my toolsets in doing that.
        </p>
        <p ref={(el) => (refParagraphs.current[17] = el)}>
          That's why I spend my days and nights at{' '}
          <a href="https://sundial.so/" target="_blank" rel="noreferrer">
            Sundial
          </a>
          . Our mission is to help creators achieve their mission by empowering them to make better decisions
          through better data understanding. I hope and hold great faith that through better decision-making
          from product creators, they can tackle these world problems at an exponentially higher caliber.
        </p>
        <p ref={(el) => (refParagraphs.current[18] = el)}>
          And that's my dream. It may feel less tangible than before, but the unending possibilities are what
          warms my heart and gets me out of bed every morning.
        </p>
        <p ref={(el) => (refParagraphs.current[19] = el)}>
          That's my life now. It's no longer about a binary state of having achieved something or not. It's
          the relentless fight for a better self, a better world.
        </p>
        <a href="https://twitter.com/jayhxmo" target="_blank" rel="noreferrer">
          <div ref={(el) => (refParagraphs.current[20] = el)} className="signature" />
        </a>
      </main>
      <div ref={refCover} className="cover" />
    </>
  );
}

export default App;
